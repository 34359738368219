.redbox{
  height: 30px;
  display:block;
  background-color: red;
}

.title{
  display: block;
  text-align: center;
  font-size: 3em;
  font-family:Georgia, 'Times New Roman', Times, serif;
}

/*html::-webkit-scrollbar {
  width: 10px;
  position:fixed
}

html::-webkit-scrollbar-track {
background: red; 
}

html::-webkit-scrollbar-thumb {
background: black; 
}*/

/* html{
  overflow:-moz-hidden-unscrollable;
} */